.cell {
  width: 96px;
  height: 64px;
  padding: 4px;
  background: url(../../../../img/stripe.png) repeat;
  border: 1px solid #666;
}

.cell.stack {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 32px);
  grid-template-rows: repeat(2, 32px);
}

.resolved {
  position:relative;
  left: 16px;
}

.unresolved {
  display: table-cell;
  width: 32px;
  height: 32px;
  transition: transform 0.1s;
  z-index: 10;
}

.unresolved:hover {
  z-index: 100;
  transform: scale(1.4);
}
