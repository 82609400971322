.canvas {
    width: 1200px;
    height: 700px;
    border: 1px solid #ccc;
    margin: 2vw;
    background: url('../img/bg.png');
    padding: 36px;
    border-radius: 0 48px 0 48px;
    -webkit-box-shadow: 0px 0px 40px -2px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 40px -2px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 40px -2px rgba(0, 0, 0, 0.34);
}