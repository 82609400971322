.text-label {
  font-size: 18pt;
  color: #710000;
  margin-bottom: 6px;
  font-weight: 500;
}

.sprite {
  background: url(../img/6x6.png) no-repeat top left;
  background-size: 600%;
}

.sprite.large {
  width: 64px;
  height: 64px;
}

.sprite.small {
  width: 32px;
  height: 32px;
}

.sprite.middle {
  width: 48px;
  height: 48px;
}


.sprite.clue.micro {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 10px;
  margin: 1px;
}

.empty {
  background: none;
}

.sprite.s00 {background-position: 0 0}
.sprite.s10 {background-position: 0 20%}
.sprite.s20 {background-position: 0 40%}
.sprite.s30 {background-position: 0 60%}
.sprite.s40 {background-position: 0 80%}
.sprite.s50 {background-position: 0 100%}
.sprite.s01 {background-position: 20% 0}
.sprite.s11 {background-position: 20% 20%}
.sprite.s21 {background-position: 20% 40%}
.sprite.s31 {background-position: 20% 60%}
.sprite.s41 {background-position: 20% 80%}
.sprite.s51 {background-position: 20% 100%}
.sprite.s02 {background-position: 40% 0}
.sprite.s12 {background-position: 40% 20%}
.sprite.s22 {background-position: 40% 40%}
.sprite.s32 {background-position: 40% 60%}
.sprite.s42 {background-position: 40% 80%}
.sprite.s52 {background-position: 40% 100%}
.sprite.s03 {background-position: 60% 0}
.sprite.s13 {background-position: 60% 20%}
.sprite.s23 {background-position: 60% 40%}
.sprite.s33 {background-position: 60% 60%}
.sprite.s43 {background-position: 60% 80%}
.sprite.s53 {background-position: 60% 100%}
.sprite.s04 {background-position: 80% 0}
.sprite.s14 {background-position: 80% 20%}
.sprite.s24 {background-position: 80% 40%}
.sprite.s34 {background-position: 80% 60%}
.sprite.s44 {background-position: 80% 80%}
.sprite.s54 {background-position: 80% 100%}
.sprite.s05 {background-position: 100% 0}
.sprite.s15 {background-position: 100% 20%}
.sprite.s25 {background-position: 100% 40%}
.sprite.s35 {background-position: 100% 60%}
.sprite.s45 {background-position: 100% 80%}
.sprite.s55 {background-position: 100% 100%}

.used {
  opacity: 0.5;
}

.help-tooltip {
  padding: 0 16px 8px 16px;
  line-height: 28px;
  width: 260px;
}

.tooltip-border {
  border: 1px solid #777 !important;
  opacity: 1 !important;
}

.right-click {
  font-size: 11px;
  padding-top: 16px;
  font-style: italic;
}