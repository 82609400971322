.icon-right {
  opacity: 0.8;
  position: absolute;
  top: 722px;
  left: 1238px;
  cursor: pointer;
}

.top-part {
  display: flex;
  flex-direction: row;
}

.bottom-part {
  margin-top: 8px;
}

.buttons {
  margin-top: 16px;
}

.bottom-button {
  font-size: 14px;
  padding: 10px 22px;
  color: #eee;
  width: 220px;
  background: #710000;
  opacity: 0.8;
  outline: none;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
}

.bottom-button:hover {
  opacity: 1;
}

.bottom-button:disabled {
  opacity: 0.5;
}