:host {
  font-weight: 400;
  font-style: normal;
  color: #333;
  text-align: justify;
}

.block1 {
  font-size: 12pt;
  font-style: italic;
  margin-bottom: 32px;
}

.block2 {
  font-size: 11pt;
  margin-bottom: 8px;
}

.block3 {
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 14pt;
  font-weight: 700;
}

.block4 {
  margin-bottom: 8px;
  font-size: 11pt;
}

.block5 {
  margin-top: 24px;
  font-size: 16pt;
}

.block6 {
  font-size: 10pt;
}

.link {
  color: #710000;
  opacity: 1;
}

.link:hover {
  opacity: 0.6;
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.icon-right {
  opacity: 0.8;
  position: absolute;
  top: 712px;
  left: 1208px;
  font-size: 48px;
  cursor: pointer;
}

button.pseudo {
  background: transparent;
    border: 0;
    font-size: 24px;
    text-decoration: underline;
    padding: 0;
    outline: 0;
    cursor: pointer;
}